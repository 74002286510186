import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Shop from '../views/Shop.vue'
import Checkout from '../views/Checkout.vue'
import Confirm from '../views/Confirm.vue'
import Stripe from '../components/common/Stripe.vue'
import Prenotazioni from '../views/Prenotazioni.vue'
import Ricevute from '../views/Ricevute.vue'
import Abbonamenti from '../views/Abbonamenti.vue'
import Configurazione from '../components/common/Configurazione.vue'
import Utenti from '../components/common/Utenti.vue'
import Anagrafica from '../views/Anagrafica.vue'
import Richieste from '../views/Richieste.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {path: '/login',  name: 'Login',    component: Login},
  {path: '/dashboard',  name: 'Dashboard',    component: Dashboard},
  {path: '/shop',  name: 'Shop',    component: Shop},
  {path: '/prenotazioni',  name: 'Prenotazioni',    component: Prenotazioni},
  {path: '/ricevute',  name: 'ricevute',    component: Ricevute},
  {path: '/abbonamenti',  name: 'abbonamenti',    component: Abbonamenti},
  {path: '/checkout',  name: 'Checkout',    component: Checkout},
  {path: '/confirm',  name: 'Confirm',    component: Confirm},
  {path: '/stripe',  name: 'Stripe',    component: Stripe},
  {path: '/configurazione',  name: 'Configurazione',    component: Configurazione},
  {path: '/utenti',  name: 'Utenti',    component: Utenti},
  {path: '/anagrafica',  name: 'Anagrafica',    component: Anagrafica},
  {path: '/richieste',  name: 'Richieste',    component: Richieste},

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
