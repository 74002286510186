<template>
    <div class="mt-4">
        <div class="col-md-12" style="padding:0px" >
            <div class="row" style="padding:0" v-show="this.$root.isMobile == false">
                <div class="col-md-12">
                    <div class="card shadow ">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col-md-9">
                                    <!--
                                    <div style="float:left">
                                        <img style="width:90px;height:90px" class="img-profile rounded-circle " :src="'CORE/UPLOAD/AVATAR/'+ utente.URL_AVATAR" >
                                    </div>-->
                                    <div style="float:left;margin-left:20px">
                                        <div class="font-weight-bold text-ec h4">Benvenuto in Deskalo!<br/><small>Il sistema per gestire online le operazioni fatte al <strong>desk</strong></small></div>  
                                        <hr>
                                        <div class="font-weight-bold text-ec h5"><i class="fal fa-user fa-fw"></i>{{utente.persona.NOME}} {{utente.persona.COGOME}} | <i class="fal fa-envelope fa-fw"></i>{{utente.EMAIL}}</div>
                                        <button class="btn btn-warning mt-4 ml-2" v-if="isAdmin" v-on:click="configurazione()">Configurazione</button>
                                        <button class="btn btn-info mt-4 ml-2" v-if="isAdmin" v-on:click="utenti()">Utenti</button>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div style="float:right" >
                                        <img style="max-width:260px" class="img-responsive"  :src="getImgHeader()">
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="row" v-show="this.$root.isMobile == true">
                <img style="max-width:260px;margin:0 auto" class="img-responsive"   :src="getImgHeader()">
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-2">

                            <div class="card shadow lift-panel" style="margin-top:20px" v-on:click="this.$root.openPopUpSceltaSocio()" v-show="this.$root.isMobile">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="font-weight-bold text-primary  mb-1">Cambia socio</div>  
                                            <div  class="h6 mb-0  text-gray-800">Seleziona un socio tra quelli associati all'utenza corrente</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fal fa-sync fa-4x" style="color:#4ba7f4"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>  

                            <div class="card shadow lift-panel" style="margin-top:20px" v-on:click="shop()" v-show="PAGAMENTI_ONLINE_ABILITATI == 1">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="font-weight-bold text-primary  mb-1">Effettua un pagamento</div>  
                                            <div v-if="home.CARRELLO.ITEMS.length > 0" class="h6 mb-0  text-gray-800">Hai: {{home.CARRELLO.ITEMS.length }} prodotti nel carrello <button v-on:click="continuaShopping()" class="btn btn-warning btn-sm">Continua la spesa</button></div> 
                                            <div v-else class="h6 mb-0  text-gray-800">Non hai prodotti nel carrello</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fal fa-shopping-cart fa-4x" style="color:#4ba7f4"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div class="card shadow lift-panel" style="margin-top:20px;background:#ddd" v-show="PAGAMENTI_ONLINE_ABILITATI == 0">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="font-weight-bold text-primary  mb-1">Pagamenti online disabilitati</div>  
                                            <div  class="h6 mb-0  text-gray-800">Chiedi alla società di abilitare i pagamenti online</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fal fa-shopping-cart fa-4x" style="color:#4ba7f4"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>                    
                        </div>       
                        <div class="col-md-2">
                            <div class="card shadow lift-panel" style="margin-top:20px" v-on:click="prenotazioni()">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="font-weight-bold text-primary  mb-1">Prenotazioni</div>  
                                            <div v-if="home.PRENOTAZIONI.length > 0" class="h6 mb-0  text-gray-800">Hai <span class="badge badge-primary">{{home.PRENOTAZIONI.length }}</span> prenotazioni attive </div>
                                            <div v-else class="h6 mb-0  text-gray-800">Non hai prenotazioni attive</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fal fa-calendar-alt fa-4x" style="color:#4ba7f4"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>                    
                        </div>   
                        
                            <div class="col-md-2">
                            <div class="card shadow lift-panel" style="margin-top:20px" v-on:click="abbonamenti()">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="font-weight-bold text-primary  mb-1">Abbonamenti validi</div>  
                                            <div v-if="home.ABBONAMENTI.length > 0" class="h6 mb-0  text-gray-800">Hai <span class="badge badge-primary">{{home.ABBONAMENTI.length }}</span> abbonamenti </div>
                                            <div v-else class="h6 mb-0  text-gray-800">Non hai abbonamenti attivi</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fal fa-check-square fa-4x" style="color:#4ba7f4"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>                    
                        </div> 
                        
                        <div class="col-md-2">
                            <div class="card shadow lift-panel" style="margin-top:20px" v-on:click="richieste()">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="font-weight-bold text-primary  mb-1">Messaggi dalla segreteria</div>  
                                            <div v-if="home.NOTIFICHE > 0" class="h6 mb-0  text-gray-800">Hai <span class="badge badge-primary">{{home.NOTIFICHE }}</span> messaggi </div>
                                            <div v-else class="h6 mb-0  text-gray-800">Non hai messaggi dalla segreteria</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fal fa-inbox fa-4x" style="color:#4ba7f4"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>                    
                        </div> 
                        <div class="col-md-2">
                            <div class="card shadow lift-panel" style="margin-top:20px" v-on:click="anagrafica()">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="font-weight-bold text-primary  mb-1">Nuova anagrafica</div>  
                                            <div class="h6 mb-0  text-gray-800">Richiedi nuovo inserimento</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fal fa-user-plus fa-4x" style="color:#4ba7f4"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>                    
                        </div> 
                        <div class="col-md-2" style="margin-bottom: 40px">
                            <div class="card shadow lift-panel" style="margin-top:20px" v-on:click="ricevute()">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="font-weight-bold text-primary  mb-1">Ricevute</div>  
                                            <div class="h6 mb-0  text-gray-800">Mostra tutte le ricevute</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fal fa-euro-sign fa-4x" style="color:#4ba7f4"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>                    
                        </div>                      
                    </div>

                </div>                
            </div>
        </div>


      
    </div>
</template>
<script>
export default {
    name:"Dashboard",
    data:function(){
      return{
        filters:global.filters,
        isAdmin:false,
        utente:{persona:{}},
        startInterval:{},
        arrayNegozi:new Array(),
        home:{CARRELLO:{ITEMS:new Array()},PRENOTAZIONI:new Array(),MESSAGGI:new Array(),ABBONAMENTI:new Array()},
        search:{
          PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:200},
          ALIAS:{TYPE:'TEXT',VALUE:''},
        },
        PAGAMENTI_ONLINE_ABILITATI:0,
      }
    },
    props: ['todo'],
    methods:{
        getImmagineSfondo : function(negozio){
            var url = 'https://gestionale.assosprint.it/CORE/SERVER/UPLOAD/IMG/LOGHI/'+ negozio.URL_LOGO;
            return {
            "background-image": "url('"+ url +"')",
            "background-position":"center",
            "background-repeat":"no-repeat",
            "background-size":"cover",    
            }
        },
        continuaShopping : function(){
            this.openNegozio({ID_NEGOZIO:this.home.CARRELLO.ID_NEGOZIO});
        },
        testEmail : function(){
            utils.ajax('/as/ricevuta/invia',{}, (response) => {
            
            });
        },
     
        prenotazioni:function(){
            global.router.push("Prenotazioni");
        },
        shop:function(){
            global.router.push("Shop");
        },
        ricevute:function(){
            global.router.push("Ricevute");
        },
        abbonamenti:function(){
            global.router.push("Abbonamenti");
        },
        anagrafica:function(){
            global.router.push("Anagrafica");
        },
        richieste:function(){
            global.router.push("Richieste");
        },
        configurazione:function(){
            global.router.push("configurazione");
        },
        utenti:function(){
            global.router.push("utenti");
        },
        getImgHeader:function(){
            return this.$root.getImgHeader();
        }
    },
    computed:{
        paginatedList(){
            var arr = utils.inGrid(this.arrayNegozi,this.search);
            return arr;
        },
    },
    created: function () {
        var params = {};
        this.$root.setSocieta(()=>{
            utils.ajax('core/home',params, (response) => {
                this.utente = response.data.UTENTE;
                for (var i = 0 ; i < this.utente.funzionalita.length ; i++){
                    if (this.utente.funzionalita[i].ID_FUNZIONALITA == 2){
                        this.isAdmin = true;
                    }
                }
                this.home = response.data;
                this.arrayNegozi = response.data.NEGOZI_ASSOSPRINT;
                this.$root.arrayNegozi = response.data.NEGOZI_ASSOSPRINT;
                this.$root.DESKALO_SOCIO_SELEZIONATO = response.data.DESKALO_SOCIO_SELEZIONATO;
                if (this.arrayNegozi.length > 1 || this.arrayNegozi[0].ARRAY_SOCI.length > 0){
                    if (!response.data.DESKALO_SOCIO_SELEZIONATO.hasOwnProperty("ID_SOCIO")){
                        this.$root.openPopUpSceltaSocio();
                    }
                    else{
                        this.PAGAMENTI_ONLINE_ABILITATI = response.data.PAGAMENTI_ONLINE_ABILITATI;
                    }
                }
                else{
                    this.PAGAMENTI_ONLINE_ABILITATI = response.data.PAGAMENTI_ONLINE_ABILITATI;
                }
            });            
        });
    },
}
</script>